import { Card } from 'components/card/card'
import { Text } from 'components/text/text'
import { format, parseISO } from 'date-fns'
import { toCurrency } from 'libs/helpers/to-currency'
import React, { memo } from 'react'
import { IModerationOrder } from 'types/bag'
import { IOrder } from 'types/order'

import { useCardOrder } from './card-order.controller'
import {
    Avatar,
    ChevronIcon,
    Container,
    ContainerAgent,
    ContainerChevron,
    ContainerHistoric,
    ContainerInfoAgent,
    ContainerSteps,
    ContainerStore,
    DeliveryIcon,
    Line,
    LineChevron,
    LogoStore,
    PickerIcon,
    Point,
    Row,
    RowInfo,
} from './card-order.styled'

type Props = {
    order?: IOrder
    moderationOrder?: IModerationOrder
}

const CardOrder: React.FC<Props> = memo(({ order, moderationOrder }) => {
    const { store, items, info, value, delivered, steps, stepsComplete, expand, _toggleExpand } =
        useCardOrder({
            o: order,
            m: moderationOrder,
        })

    return (
        <Card>
            <Container>
                {!!store && (
                    <ContainerStore>
                        <LogoStore src={store.logo} />
                        <div>
                            <Text text={store.name} bold dimensions="large" />
                        </div>
                        <Text text={value} className="total" dimensions="medium" />
                    </ContainerStore>
                )}
                <Text
                    text={info}
                    dimensions="small"
                    align="center"
                    light
                    marginTop={4}
                    weight="500"
                    marginBottom={10}
                />

                <ContainerSteps>
                    {steps.map(
                        (item, i) =>
                            item?.show_step && (
                                <React.Fragment key={i}>
                                    <Line
                                        amountSteps={item?.show_step ? steps.length - 1 : steps.length}
                                        fail={item.fail}
                                        complete={delivered || i < stepsComplete.length}
                                    />
                                </React.Fragment>
                            ),
                    )}
                </ContainerSteps>
                {!!order?.dm_order?.times?.canceled_at && (
                    <RowInfo fail>
                        <Point className="point" />
                        <Text className="step-name" text="Pedido Cancelado" dimensions="xSmall" />
                        <Text
                            text={format(parseISO(order.dm_order.times.at_client), 'HH:mm')}
                            dimensions="xSmall"
                        />
                    </RowInfo>
                )}
                {stepsComplete.map((item, i) => (
                    <RowInfo key={i} active={item.active} fail={item.fail}>
                        <Point className="point" />
                        <Text className="step-name" text={item.name} dimensions="xSmall" />
                        <Text text={item.time} dimensions="xSmall" />
                    </RowInfo>
                ))}
                <ContainerHistoric amountSteps={items.length}>
                    <Row>
                        <Text text="Itens" dimensions="xxSmall" bold />
                        <Text text="Valor" bold />
                    </Row>
                    {expand && (
                        <>
                            {items.map((item, i) => (
                                <RowInfo key={i}>
                                    <Text text={`${item.quantity}x`} dimensions="xSmall" />
                                    <Text className="step-name" text={`${item.name}`} dimensions="xSmall" />
                                    <Text
                                        text={toCurrency(Number(item?.total_price || item.price))}
                                        dimensions="xSmall"
                                    />
                                </RowInfo>
                            ))}
                        </>
                    )}
                    <Row>
                        <Text text="Total" dimensions="xxSmall" bold />
                        <Text
                            text={toCurrency(
                                items.reduce((prev, item) => {
                                    return prev + Number(item?.total_price || item.price)
                                }, 0),
                            )}
                            bold
                        />
                    </Row>
                </ContainerHistoric>
                <ContainerChevron onClick={_toggleExpand}>
                    <LineChevron />
                    <ChevronIcon icon={expand ? 'chevron-up' : 'chevron-down'} />
                    <LineChevron />
                </ContainerChevron>

                {!!order?.dm_order?.agent && (
                    <ContainerAgent>
                        <Avatar src={order.dm_order.agent.avatar} />
                        <ContainerInfoAgent>
                            <Text text="Responsável" dimensions="xSmall" marginBottom={2} />
                            <Row>
                                <Text text={order.dm_order.agent.name} dimensions="large" />
                                <DeliveryIcon />
                            </Row>
                        </ContainerInfoAgent>
                    </ContainerAgent>
                )}
                {!!order?.dm_order?.picker && (
                    <ContainerAgent>
                        <Avatar src={order.dm_order.picker.avatar} />
                        <ContainerInfoAgent>
                            <Text text="Responsável" dimensions="xSmall" marginBottom={2} />
                            <Row>
                                <Text text={order.dm_order.picker.name} dimensions="large" />
                                <PickerIcon />
                            </Row>
                        </ContainerInfoAgent>
                    </ContainerAgent>
                )}
            </Container>
        </Card>
    )
})

export { CardOrder }
