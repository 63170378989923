import { Text } from 'components'
import { forwardRef, memo, useImperativeHandle } from 'react'

import { useOrderWay } from './order-way.controller'
import { Background, Box, Close, Container, Header, Image, Square } from './order-way.styled'

type OrderWayBase = {
    show?(): void
}

const OrderWay = memo(
    forwardRef<OrderWayBase, any>((props, ref) => {
        const { show, visible, _show, _close } = useOrderWay()

        useImperativeHandle(
            ref,
            () => ({
                show: _show,
            }),
            [_show],
        )

        if (!visible) {
            return null
        }

        return (
            <Container>
                <Background />
                <Box show={show}>
                    <Header>
                        <Text
                            text="Seu pedido já está a caminho!"
                            uppercase
                            bold
                            dimensions="xxLarge"
                            variant="foregroundPrimary"
                            align="center"
                        />
                        <Text
                            text="não se preocupe, nosso entregador já está quase aí! já já liberamos seu mapa ;)"
                            dimensions="xSmall"
                            variant="foregroundPrimary"
                            align="center"
                        />
                        <Square />
                        <Close icon="times" onClick={_close} />
                    </Header>
                    <Image />
                </Box>
            </Container>
        )
    }),
)

export { OrderWay }
