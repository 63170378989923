import 'mapbox-gl/dist/mapbox-gl.css'

import { centerOfMass, polygon } from '@turf/turf'
import { Text } from 'components/text/text'
import { useOrder } from 'libs/hooks'
import mapboxgl from 'mapbox-gl'
import React, { memo, useCallback, useMemo, useState } from 'react'
import MapBox, { Marker, Popup } from 'react-map-gl'
import { IAgent } from 'types/agent'
import { IOrder } from 'types/order'

import { Container, IconAgent, IconCustomer, IconStore, MockMap } from './map.styled'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

type Props = {
    children?: any
}

const Map: React.FC<Props> = memo(({ children }) => {
    const { orders, info } = useOrder()

    const bounds = useMemo<any>(() => {
        const bounds: number[][] = []

        orders.forEach(order => {
            if (info?.address?.location) {
                bounds.push([info.address.location.lng, info.address.location.lat])
            }
            if (order.store?.location) {
                bounds.push([order.store.location.lng, order.store.location.lat])
            }
            if (order.dm_order?.agent?.location) {
                bounds.push([order.dm_order.agent.location.lng, order.dm_order.agent.location.lat])
            }
        })

        return bounds
    }, [orders, info])

    const location = useMemo(() => {
        const points = [...bounds]
        if (points.length > 0) {
            points.push([...bounds[0]])
        }

        if (points.length > 0) {
            points.push([...points[0]])
        }
        if (!points || points.length < 1) {
            return
        }
        try {
            const poly = polygon([points])

            return centerOfMass(poly)
        } catch (error) {
            return undefined
        }
    }, [bounds])

    const enable = useMemo(() => {
        return !!orders.find(x => !!x?.dm_order?.times.start_delivery && !x?.dm_order?.times.at_client)
    }, [orders])

    const agents = useMemo(() => {
        const agents: IAgent[] = []
        const agentsName: string[] = []
        orders.forEach(o => {
            if (o.dm_order?.agent) {
                if (!agentsName.includes(o.dm_order.agent.name)) {
                    agents.push(o.dm_order.agent)
                    agentsName.push(o.dm_order.agent.name)
                }
            }
        })
        return agents
    }, [orders])

    return (
        <Container className="map">
            {enable && !!location ? (
                <MapBox
                    initialViewState={{
                        bounds,
                        longitude: location.geometry.coordinates[0],
                        latitude: location.geometry.coordinates[1],
                        fitBoundsOptions: {
                            padding: 100,
                        },
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                >
                    {agents.map((agent, i) => (
                        <MarkerAgent key={i} agent={agent} />
                    ))}

                    {orders.map((item, i) => (
                        <React.Fragment key={i}>
                            <MarkerCustomer order={item} />
                            <MarkerStore order={item} />
                        </React.Fragment>
                    ))}
                </MapBox>
            ) : (
                <MockMap />
            )}
            {children}
        </Container>
    )
})

type OrderProps = {
    order: IOrder
}
const MarkerCustomer: React.FC<OrderProps> = memo(({ order }) => {
    const [showPopup, setShowPopup] = useState(false)
    const { info } = useOrder()

    const location = useMemo(() => {
        return info?.address?.location
    }, [info])

    const _showPopup = useCallback((value: boolean) => {
        return () => setShowPopup(value)
    }, [])

    if (!location) {
        return null
    }

    return (
        <>
            <Marker
                anchor="center"
                latitude={location.lat}
                longitude={location.lng}
                onClick={_showPopup(true)}
            >
                <IconCustomer />
            </Marker>
            {showPopup && (
                <Popup
                    latitude={location.lat}
                    longitude={location.lng}
                    anchor="bottom"
                    style={{ marginBottom: 20 }}
                    closeOnClick={false}
                    closeOnMove={false}
                    onClose={_showPopup(false)}
                >
                    <Text text={info?.details?.customer} marginTop={5} marginBottom={5} bold />
                </Popup>
            )}
        </>
    )
})
type AgentProps = {
    agent: IAgent
}

const MarkerAgent: React.FC<AgentProps> = memo(({ agent }) => {
    const [showPopup, setShowPopup] = useState(false)

    const location = useMemo(() => {
        return agent.location
    }, [agent])

    const _showPopup = useCallback((value: boolean) => {
        return () => setShowPopup(value)
    }, [])

    if (!location) {
        return null
    }

    return (
        <>
            <Marker
                anchor="bottom"
                latitude={location.lat}
                longitude={location.lng}
                onClick={_showPopup(true)}
            >
                <IconAgent />
            </Marker>
            {showPopup && (
                <Popup
                    latitude={location.lat}
                    longitude={location.lng}
                    anchor="bottom"
                    style={{ marginBottom: 20 }}
                    closeOnClick={false}
                    closeOnMove={false}
                    onClose={_showPopup(false)}
                >
                    <Text text="Entregador" marginTop={5} marginBottom={5} bold />
                </Popup>
            )}
        </>
    )
})

const MarkerStore: React.FC<OrderProps> = memo(({ order }) => {
    const [showPopup, setShowPopup] = useState(false)

    const location = useMemo(() => {
        return order?.store?.location
    }, [order])

    const _showPopup = useCallback((value: boolean) => {
        return () => setShowPopup(value)
    }, [])

    if (!location) {
        return null
    }

    return (
        <>
            <Marker
                anchor="center"
                latitude={location.lat}
                longitude={location.lng}
                onClick={_showPopup(true)}
            >
                <IconStore />
            </Marker>
            {showPopup && (
                <Popup
                    latitude={location.lat}
                    longitude={location.lng}
                    anchor="bottom"
                    style={{ marginBottom: 20 }}
                    closeOnClick={false}
                    closeOnMove={false}
                    onClose={_showPopup(false)}
                >
                    <Text text={order?.store?.name} marginTop={5} marginBottom={5} bold />
                </Popup>
            )}
        </>
    )
})
export { Map }
