import { Button, Canvas, Card, CardOrder, FooterApp, Map, OrderInfo, Scroll, Text } from 'components'
import { TabsContainer } from 'components/tabs-container/tabs-container'
import { formatAddress } from 'libs/helpers/format-address'
import { getSaleChannelIcon } from 'libs/helpers/get-sale-channel-icon'
import { useOrder } from 'libs/hooks'
import { useIsMobileDevice } from 'libs/hooks/use-window-dimensions'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import {
    CloseIcon,
    Container,
    ContainerInfoMall,
    ContainerMall,
    ContainerMap,
    Content,
    HeaderMap,
    LocationIcon,
    Logo,
    MapHeaderContainer,
} from './left-order-info.styled'

const LeftOrderInfo = memo(() => {
    const isMobile = useIsMobileDevice()
    const { saleDetails, messageError, orders, moderationOrders, info, isPresential } = useOrder()

    const [isFull, setFullScreen] = useState(false)

    const titleCard = useMemo(() => {
        return saleDetails?.type === 'takeout' ? 'Local de Retirada' : 'Local de Entrega'
    }, [saleDetails])

    const mapTabs = useMemo(() => {
        const tabs: string[] = []
        info?.details?.destinies.forEach(item => {
            const stores = item.maps.map(map => {
                return map?.store?.name
            })
            tabs.push(...stores)
        })
        return tabs
    }, [info])

    const _toggleFullScreen = useCallback(() => {
        setFullScreen(state => !state)
    }, [])

    return (
        <Container>
            <Content>
                <Scroll>
                    {messageError ? (
                        <Text text={messageError} light marginTop={100} align="center" dimensions="xLarge" />
                    ) : (
                        <>
                            <Card>
                                <ContainerMall>
                                    {/* <Logo src={getSaleChannelIcon(saleDetails?.name)} /> */}
                                    <ContainerInfoMall>
                                        <Text
                                            text={saleDetails?.name}
                                            dimensions="xxLarge"
                                            variant="primary"
                                        />
                                        <Text
                                            text={`Modalidade da entrega: ${saleDetails?.type_label}`}
                                            weight="500"
                                            dimensions="xxSmall"
                                        />
                                    </ContainerInfoMall>
                                </ContainerMall>
                            </Card>
                            {moderationOrders.map((item, i) => (
                                <CardOrder moderationOrder={item} key={i} />
                            ))}
                            {orders.map((item, i) => (
                                <CardOrder order={item} key={i} />
                            ))}
                            {isMobile && (
                                <Card>
                                    <Text
                                        text={titleCard}
                                        dimensions="xxLarge"
                                        variant="primary"
                                        bold
                                        marginBottom={8}
                                    />
                                    <TabsContainer tabs={mapTabs}>
                                        {isPresential && info?.details?.destinies?.length ? (
                                            info?.details?.destinies.map((destiny, index) =>
                                                destiny.maps.map(map => (
                                                    <ContainerMap key={index} full={isFull}>
                                                        {isFull && (
                                                            <HeaderMap>
                                                                <Text
                                                                    text={titleCard}
                                                                    dimensions="xxLarge"
                                                                    variant="primary"
                                                                    bold
                                                                />
                                                                <CloseIcon onClick={_toggleFullScreen} />
                                                            </HeaderMap>
                                                        )}

                                                        <Canvas
                                                            destiny={{ ...destiny, maps: [map] }}
                                                            hasZoom={!isFull && isMobile}
                                                        />
                                                        {!isFull && (
                                                            <>
                                                                <MapHeaderContainer>
                                                                    <LocationIcon />
                                                                    <Text
                                                                        text={`${destiny?.name} - ${map.name}
                                                                            `}
                                                                        dimensions="xSmall"
                                                                    />
                                                                </MapHeaderContainer>
                                                                {saleDetails?.type !== 'delivery' && (
                                                                    <Button
                                                                        title="Ver no Mapa"
                                                                        onClick={_toggleFullScreen}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </ContainerMap>
                                                )),
                                            )
                                        ) : (
                                            <ContainerMap full={isFull}>
                                                {isFull && (
                                                    <HeaderMap>
                                                        <Text
                                                            text={titleCard}
                                                            dimensions="xxLarge"
                                                            variant="primary"
                                                            bold
                                                        />
                                                        <CloseIcon onClick={_toggleFullScreen} />
                                                    </HeaderMap>
                                                )}
                                                <Map />

                                                <MapHeaderContainer>
                                                    <LocationIcon />
                                                    <Text
                                                        text={formatAddress(info?.address)}
                                                        dimensions="xSmall"
                                                    />
                                                </MapHeaderContainer>
                                            </ContainerMap>
                                        )}
                                    </TabsContainer>
                                </Card>
                            )}
                            <Card>
                                <OrderInfo />
                            </Card>
                        </>
                    )}
                </Scroll>
            </Content>
            <FooterApp />
        </Container>
    )
})

export { LeftOrderInfo }
