import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'
import styled, { css } from 'styled-components'

const Container = styled.div`
    z-index: ${({ theme }) => theme.elevation.tier1};
`

const Line = styled.div<{ amountSteps?: number; complete?: boolean; fail?: boolean }>`
    width: ${({ amountSteps }) =>
        amountSteps ? `calc((100% - (${amountSteps * 8}px)) /${amountSteps} )` : '80px'};
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
    background-color: ${({ theme, complete, fail }) => {
        if (fail) {
            return theme.colors.danger
        }
        return complete ? theme.colors.success : theme.colors.backgroundContrast
    }};
    transition: background-color 500ms;
    margin-right: 8px;
    &:last-child {
        margin-right: 0px;
    }
`

const ContainerSteps = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

const ContainerHistoric = styled.div<{ expand?: boolean; amountSteps?: number }>`
    margin-top: 10px;
    overflow: hidden;
    transition: height 500ms;
`

const Row = styled.div`
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Point = styled.div<{ complete?: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: ${({ theme, complete }) => (complete ? theme.colors.success : theme.colors.text)};
`

const RowInfo = styled.div<{ active?: boolean; fail?: boolean }>`
    display: flex;
    margin: 5px 0px;
    gap: 0.5rem;
    .step-name {
        flex: 1;
    }
    ${({ active, theme }) =>
        active &&
        css`
            .text {
                color: ${theme.colors.success};
            }
            .point {
                background-color: ${theme.colors.success};
            }
        `}
    ${({ fail, theme }) =>
        fail &&
        css`
            .text {
                color: ${theme.colors.danger};
            }
            .point {
                background-color: ${theme.colors.danger};
            }
        `}
`

const ContainerChevron = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const LineChevron = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.colors.text};
    flex: 1;
`

const ChevronIcon = styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    margin: 0px 10px;
`

const DeliveryIcon = styled(FontAwesomeIcon).attrs({
    icon: 'motorcycle',
})`
    width: 1.5rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.primary};
`

const PickerIcon = styled(LogarooIcon).attrs({
    icon: 'tray',
    size: 'xLarge',
    variant: 'primary',
})`
    width: 1.5rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.primary};
`

const ContainerStore = styled.div`
    display: flex;
    align-items: center;
    .total {
        flex: 1;
        text-align: right;
    }
`

const LogoStore = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-right: 10px;
`

const ContainerAgent = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`

const Avatar = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-right: 10px;
`

const ContainerInfoAgent = styled.div`
    flex: 1;
`

export {
    Avatar,
    ChevronIcon,
    Container,
    ContainerAgent,
    ContainerChevron,
    ContainerHistoric,
    ContainerInfoAgent,
    ContainerSteps,
    ContainerStore,
    DeliveryIcon,
    Line,
    LineChevron,
    LogoStore,
    PickerIcon,
    Point,
    Row,
    RowInfo,
}
